@import "./mouldifi/bootstrap.scss";
@import "./mouldifi/app.scss";
@import "../../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css";
@import "../../node_modules/react-table/react-table.css";
@import "datepicker";
@import "tabs";

.app-main-header {
    z-index: 50;

    .app-header-toggle-nav {
        position: relative;
        z-index: 100;
    }

    .app-header-logo {
        width: 180px;
        margin: 0px;
        margin-left: -90px;
        text-align: center;
        position: absolute;
        top: 0px;
        left: 50%;
        z-index: 110;
    }
}

.app-sidebar {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 90000;
    transition: all 200ms;

    &.sidebar-hidden, &.sidebar-hidden .rc-drawer-sidebar {
        left: 0 - $side-nav-width;
    }
}

@-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
}

h1 {
    font-size: 22px;
}