/*Avatar Styles*/
.user-avatar {
  @extend %size-50;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
  @include justify-content(center);
  @include align-items(center);
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  @include border-radius(50%);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border: $jr-border;

  &.avatar-shadow {
    border: 0 none;
  }
}

.user-profile {
  @include display-flex();
  @include align-items(flex-start);

  & .user-detail {
    -webkit-flex: 1;
    flex: 1;
    -ms-flex-align: center;
    -webkit-align-self: center;
    align-self: center;
  }
}

.user-detail {
  & .user-name {
    margin-bottom: 2px;
    font-weight: 400;
    text-transform: capitalize;
  }
  & .user-description {
    font-size: 12px;
    margin-bottom: 0;
    color: $gray-500;
  }
}

.user-thumb {
  position: relative;

  .messages-list & {
    margin-right: 8px;
  }
}
