/*E-commerce Styles*/

.product-item {
  overflow: hidden;
}

.product-item-vertical {
  @extend .product-item;

  & .card-image .grid-thumb-equal {
    padding-bottom: 110%;

    @media screen and (max-width: 1199px) {
      padding-bottom: 74%;
    }

    @media screen and (max-width: 991px) {
      padding-bottom: 110%;
    }

    @media screen and (max-width: 829px) {
      padding-bottom: 120%;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 44%;
    }
  }
}



