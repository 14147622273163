.tabs-container {
    margin-top: 15px;

    ul.tabs-navigation {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            padding: 10px 10px;
            margin: 0px;
            float: left;
            background: rgba(255,255,255,0.2);
            border-right: 1px solid rgba(0,0,0,0.05);
            cursor: pointer;

            &:hover {
                background: rgba(255,255,255,0.5);
            }

            &:first-child {
                border-top-left-radius: 5px;
            }

            &:last-child {
                border-right: none;
                border-top-right-radius: 5px;
            }

            &.active {
                background: rgba(255,255,255,1);
                font-weight: bold;
            }
        }
    }

    .tabs-content-container {
        background: #fff;

        .tabs-content {
            padding: 15px;
        }

        > .list-group {
            border: none;
            border-radius: 0px;

            div.list-group-item {
                border-left: none;
                border-right: none;

                &:first-child {
                    border-radius: 0px;
                    border-top: none;
                }

                &:last-child {
                    border-radius: 0px;
                    border-bottom: none;
                }
            }
        }
    }
}