.react-datepicker {
    border: 1px solid #ced4da;

    .react-datepicker__header {
        background: #fff;
        font-family: "Nunito Sans", sans-serif;
        border-bottom: 1px solid #ced4da;
    }

    .react-datepicker__navigation.react-datepicker__navigation--previous {
        border-right-color: #a2a2a2;
    }
    .react-datepicker__navigation.react-datepicker__navigation--next {
        border-left-color: #a2a2a2;
    }

    .react-datepicker__month {
        .react-datepicker__week {
            .react-datepicker__day {
                &:hover {
                    background: rgba(0,0,0,0.05);
                }

                &.react-datepicker__day--selected {
                    background: #fbbf00;
                    font-weight: bold;
                    color: #fff;
                }

                &.react-datepicker__day--today {
                    font-weight: bold;
                }
            }
        }
    }
}
